// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { POST_FROM_LOCATION_WORKER_EVENT, POST_FROM_SYNTAX_WORKER_EVENT, POST_TO_LOCATION_WORKER_EVENT, POST_TO_SYNTAX_WORKER_EVENT } from "./events";
import { resolveCatalogEntry } from "../sqlUtils";
import { CancellablePromise } from 'api/cancellablePromise';
import { applyCancellable } from 'catalog/catalogUtils';
import huePubSub from 'utils/huePubSub';
var whenWorkerIsReady = function whenWorkerIsReady(worker, message) {
  if (window) {
    message.hueBaseUrl = window.HUE_BASE_URL;
    if (!worker.isReady) {
      window.clearTimeout(worker.pingTimeout);
      worker.postMessage({
        ping: true,
        hueBaseUrl: message.hueBaseUrl
      });
      worker.pingTimeout = window.setTimeout(function () {
        whenWorkerIsReady(worker, message);
      }, 500);
    } else {
      // To JSON and back as Vue creates proxy objects with methods which are not serializable
      worker.postMessage(JSON.parse(JSON.stringify(message)));
    }
  }
};
var attachEntryResolver = function attachEntryResolver(location, connector, namespace, compute) {
  location.resolveCatalogEntry = function (options) {
    if (!options) {
      options = {};
    }
    if (location.resolvePathPromise && !location.resolvePathPromise.cancelled) {
      applyCancellable(location.resolvePathPromise, options);
      return location.resolvePathPromise;
    }
    if (!location.identifierChain && !location.colRef) {
      if (!location.resolvePathPromise) {
        location.resolvePathPromise = CancellablePromise.reject();
      }
      return location.resolvePathPromise;
    }
    var promise = resolveCatalogEntry({
      connector: connector,
      namespace: namespace,
      compute: compute,
      temporaryOnly: options.temporaryOnly,
      cancellable: options.cancellable,
      cachedOnly: options.cachedOnly,
      identifierChain: location.identifierChain || location.colRef.identifierChain,
      tables: location.tables || location.colRef && location.colRef.tables || undefined
    });
    if (!options.cachedOnly) {
      location.resolvePathPromise = promise;
    }
    return promise;
  };
};
export var attachSyntaxWorkerEvents = function attachSyntaxWorkerEvents(syntaxWorker) {
  if (!syntaxWorker) {
    return;
  }
  syntaxWorker.onmessage = function (e) {
    if (e.data.ping) {
      syntaxWorker.isReady = true;
    } else {
      huePubSub.publish(POST_FROM_SYNTAX_WORKER_EVENT, e);
    }
  };
  huePubSub.subscribe(POST_TO_SYNTAX_WORKER_EVENT, function (message) {
    whenWorkerIsReady(syntaxWorker, message);
  });
};
export var attachLocationWorkerEvents = function attachLocationWorkerEvents(locationWorker) {
  if (!locationWorker) {
    return;
  }
  locationWorker.onmessage = function (e) {
    if (e.data.ping) {
      locationWorker.isReady = true;
    } else {
      if (e.data.locations) {
        e.data.locations.forEach(function (location) {
          attachEntryResolver(location, e.data.connector, e.data.namespace, e.data.compute);
        });
      }
      huePubSub.publish(POST_FROM_LOCATION_WORKER_EVENT, e);
    }
  };
  huePubSub.subscribe(POST_TO_LOCATION_WORKER_EVENT, function (message) {
    whenWorkerIsReady(locationWorker, message);
  });
};