// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export var getFromLocalStorage = function getFromLocalStorage(key, defaultValue) {
  var defaultOrNull = typeof defaultValue !== 'undefined' ? defaultValue : null;
  if (!window.localStorage || window.DISABLE_LOCAL_STORAGE) {
    return defaultOrNull;
  }
  var userKey = window.LOGGED_USERNAME ? "".concat(window.LOGGED_USERNAME, ".").concat(key) : key;
  var storedValue = window.localStorage.getItem(userKey);
  if (storedValue === null) {
    return defaultOrNull;
  }
  if (storedValue && storedValue.length) {
    try {
      return JSON.parse(storedValue);
    } catch (e) {}
    return storedValue;
  }
  return defaultOrNull;
};
export var setInLocalStorage = function setInLocalStorage(key, value) {
  if (!window.localStorage) {
    return;
  }
  if (window.DISABLE_LOCAL_STORAGE) {
    window.localStorage.clear();
    return;
  }
  var userKey = window.LOGGED_USERNAME ? "".concat(window.LOGGED_USERNAME, ".").concat(key) : key;
  if (typeof value === 'undefined' || value === null) {
    window.localStorage.removeItem(userKey);
  } else {
    var jsonString = undefined;
    try {
      jsonString = JSON.stringify(value);
    } catch (e) {}
    try {
      window.localStorage.setItem(userKey, jsonString || String(value));
    } catch (e) {}
  }
};
export var hueLocalStorage = function hueLocalStorage(key, value) {
  if (typeof value !== 'undefined') {
    setInLocalStorage(key, value);
    return value;
  }
  return getFromLocalStorage(key);
};
export var withLocalStorage = function withLocalStorage(key, observable, defaultValue) {
  var noInit = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var cachedValue = getFromLocalStorage(key);
  if (!noInit && cachedValue !== null) {
    observable(cachedValue);
  } else if (defaultValue) {
    observable(defaultValue);
  }
  return observable.subscribe(function (newValue) {
    setInLocalStorage(key, newValue);
  });
};