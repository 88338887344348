function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

var dfs = function dfs(node, callback) {
  if (!node || _typeof(node) !== 'object') {
    return;
  }
  Object.keys(node).forEach(function (key) {
    callback(node, key);
    dfs(node[key], callback);
  });
};
var deleteAllEmptyStringKeys = function deleteAllEmptyStringKeys(node) {
  dfs(node, function (node, key) {
    if (node[key] || typeof node[key] !== 'string') {
      return;
    }
    delete node[key];
  });
};
export default deleteAllEmptyStringKeys;